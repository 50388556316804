
import React, { useState } from 'react';
import { Box,IconButton, Grid2, TextField } from '@mui/material';
import Header from '../Components/Header';
import headerbg from '../Images/headerbg.png'
import Colors from '../Components/Colors';
import Footer from '../Components/Footer';
import { useEffect } from 'react';
import {useLocation } from 'react-router-dom';
import TypographyComponent from '../Components/TypographyComponent';
import { useMediaQuery } from '@mui/material';
import bgmob from '../Images/bgmob.jpg';
import MailIcon from '@mui/icons-material/Mail';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ButtonRed from '../Components/ButtonRed';
import TextfieldComponent from '../Components/TextFeildComponent'
import emailjs from 'emailjs-com';
import { Helmet } from 'react-helmet-async';
import ogImage from '../Images/logo.png'



const ContactUs = () => {

    const location = useLocation();
    const isDesktop = useMediaQuery('(min-width:768px)');
    const [nickName, setNickName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNo, setPhoneNo] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [isSuccessMessage, setIsSuccessMessage] = useState(false)
    const [nickNameError, setNickNameError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [phoneError, setPhoneError] = useState("");


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const focusLabel = params.get('focus');
        if (focusLabel) {
            const element = document.getElementById(focusLabel);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handleNickNameChange = (event) => {
      setNickName(event.target.value);
      setNickNameError('')
    };
  
    const handleLastNameChange = (event) => {
      setLastName(event.target.value);
    };
  
    const handlePhoneNoChange = (event) => {
      setPhoneNo(event.target.value);
      setPhoneError('')
    };
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
    const handleMessageChange = (event) => {
      setMessage(event.target.value);
      setMessageError('')
    };
  
   
    const handleSubmitButtonClick = async (event) => {
      event.preventDefault();
      setNickNameError('');
      setPhoneError('');
      setMessageError('');
      let isValid = true;
    
      if (!nickName) {
        setNickNameError('Nick name is required');
        isValid = false;
      }
      const phoneRegex = /^\d{10,12}$/;
      if (!phoneNo) {
        setPhoneError('Phone number is required');
        isValid = false;
      } else if (!phoneRegex.test(phoneNo)) {
        setPhoneError('Phone number must be 10 to 12 digits');
        isValid = false;
      }
    
      if (!message) {
        setMessageError('Message is required');
        isValid = false;
      }
    
      if (isValid) {
        try {
          const form = document.getElementById('contact-form');
          const result = await emailjs.sendForm(
            'service_a2r0n27',
            'template_0uir8e7',
             form,
            'S5JRYws8ySUwRvLBZ'
          );
    
          if (result.status === 200) {
            setNickName('');
            setNickNameError('');
            setPhoneNo('');
            setPhoneError('');
            setMessage('');
            setMessageError('');
            setIsSuccessMessage(true);
          }
        } catch (error) {
          console.error("There was an error submitting the form:", error);
        }
      }
    };
    


        useEffect(() => {
      if (isSuccessMessage) {
        const timer = setTimeout(() => {
          setIsSuccessMessage(false);
        }, 8000); // 30 seconds      
        return () => clearTimeout(timer);
      }
    }, [isSuccessMessage]);
  
    const handleKeyDownPhone = (event) => {
      const key = event.key;
      if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
        event.preventDefault();
      }
    };
  
    const [isTermPopUp, setIsTermPopUp] = useState(false);
  
    const handleTermsAndCondtionClick = () => {
      setIsTermPopUp(true)
  
    };
  
    const handleCloseTerm = () => {
      setIsTermPopUp(false)
  
    };
  
  


    return (
        <Grid2 container sx={{
            width: '100%', height: '100%', overflow: 'hidden',
            justifyContent: 'center', alignItems: 'center'
        }}>

        <Helmet>
            <title>Social media Marketing | Social Media Strategy| SEO services</title>
            <meta name="description" content=" Looking for digital marketing & SEO services? Get in touch with our services for Digital Marketing ,SEO,PPC,SMM & more. Contact our experts for a consultation." />
            <link rel="canonical" href="https://helpingflock.com/contact-us" />
            <meta property="og:title" content="Contact Us | Digital Marketing & SEO Services | Helping Flock" />
            <meta property="og:description" content="Reach out to Helping Flock for professional digital marketing services including SEO, PPC, SMM, and more. Contact us for a free consultation." />
            <meta property="og:image" content={ogImage} />
            <meta property="og:url" content="https://helpingflock.com/contact-us" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Helping Flock" />
            <script type="application/ld+json">
            {`
                {
                    "@context": "http://schema.org",
                    "@type": "WebPage",
                    "name": "Contact Us - Helping Flock",
                    "url": "https://helpingflock.com/contact-us",
                    "description": "Contact Helping Flock for expert digital marketing and SEO services. Reach out to us for consultation and support in SEO, PPC, SMM, and more.",
                    "mainEntityOfPage": "https://helpingflock.com/contact-us",
                    "potentialAction": {
                        "@type": "ContactAction",
                        "target": "mailto:contact@helpingflocks.com",
                        "query-input": "required name=email"
                    }
                }
            `}
        </script>
        </Helmet>
            <h1 style={{ position: 'absolute', left: '-999999px' }}>Search Engine Optimization</h1>
           
            <Box sx={{
                display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center',
                alignItems: 'center', position: 'relative'
            }} >
                <Box sx={{
                    width: '100%', height: '410px', backgroundImage: `url(${isDesktop ?headerbg:bgmob})`, backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover', position: 'relative', zIndex: 1, justifyContent: 'center', display: 'flex',
                    alignItems: 'center', flexDirection: 'column', gap: {sm:'54px',md:'54px',xs:'8px'}
                }} >
                    <Box sx={{
                        position: 'absolute', top: 0, left: 0, width: '100%',
                        height: '100%', backgroundColor: 'rgba(45, 45, 45, 0.48)'
                    }} />
                    <Header currentPage={'Contact us'} sx={{ position: 'absolute', top: 0, left: 0, width: '100%', zIndex: 2 }} />
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100%)', justifyContent: 'center',
                        alignItems: 'center'
                    }} >
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100%)', justifyContent: 'center',
                            alignItems: 'center', maxWidth: '90%', maxHeight: '90%', gap: '32px'
                        }} >

                            <TypographyComponent component="h2" sx={{
                                fontWeight: 700, fontSize: {sm:'48px',md:'48px',xs:'24px'}, color: '#ffffff', zIndex: 9,
                                width: '100%', lineHeight: {sm:'56px',md:'56px',xs:'28px'}, textAlign: 'center'
                            }}>
                                Contact Us</TypographyComponent>

                        </Box>

                    </Box>
                </Box>

            </Box>

            <Box sx={{
                height: '95%', display: 'flex', width: '100%', maxWidth: '90%', gap: '34px',
                paddingTop: {sm:'2%',md:'2%',xs:'5%'}, paddingBottom: '3%', flexDirection: 'column'
            }}>
                <Box  sx={{ display: 'flex', flexDirection: 'column', gap: {sm:'16px',md:'16px',xs:'8px'} }}>
                    <TypographyComponent component="h3" sx={{ fontWeight: 500,fontSize: {sm:'32px',md:'32px',xs:'14px'},
                     lineHeight:{sm:'36px',md:'36px',xs:'16px'} , color: Colors.black_80 }}>
                        Get in Touch
                    </TypographyComponent>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <TypographyComponent component="p" sx={{
                            fontWeight: 400, fontSize: {sm:'18px',md:'18px',xs:'12px'},
                            lineHeight: {sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
                        }}>
                            We’d love to hear from you! If you have questions about our services, need assistance with your current digital marketing strategy, or want to explore how Helping Flocks can elevate your brand, please reach out to us. You can send us a message directly through the contact form below or email us at  <a href="mailto:contact@brohandy.com" style={{ textDecoration: 'none', fontWeight:400,color: Colors.black_80, paddingLeft:'4px' }}>
                        contact@helpingflocks.com
                        </a>. We’re committed to providing you with the information and support you need and will respond to your inquiry as quickly as possible.
                        </TypographyComponent>
                        <TypographyComponent component="h4" sx={{
                            fontWeight: 400, fontSize: {sm:'18px',md:'18px',xs:'12px'},
                            lineHeight: {sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
                        }}>
                            Ready to start a conversation? Fill out the message form or send us an email, and a member of our team will be in touch with you shortly. We look forward to connecting with you and discussing how we can help your brand achieve its goals.
                        </TypographyComponent>
                       
                    </Box>
                    <TypographyComponent component="h4"
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', cursor: 'pointer', 
                            color: Colors.blue_6, fontSize:{xs:'12px',sm:'18px',md:'18px'},fontWeight:400 }}>
                        <MailIcon sx={{ color: Colors.black_70, fontSize:{sm:'18px',md:'18px',xs:'12px'}  }} />                       
                        <a href="mailto:contact@brohandy.com" style={{ textDecoration: 'none', fontWeight:400,color: Colors.linkblue, paddingLeft:'4px' }}>
                        contact@helpingflocks.com
                        </a>
                    </TypographyComponent>
                  {!isSuccessMessage && 

            <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display:  'flex', 
                flexDirection: 'column',gap:'8px', border:'1px solid', borderColor: Colors.black_60, borderRadius:'4px',}}>             
              <Box sx={{width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center',
                             display:  'flex', maxWidth: { xs:'90%', md:'95%' } , flexDirection: 'column',gap:'8px',
                             paddingTop:{ xs:'5%', md:'2.5%' }, paddingBottom: { xs:'5%', md:'2.5%' } , }}>
              
              
                
 <form onSubmit={handleSubmitButtonClick} id="contact-form" style={{width:'100%', gap:'16px'}}>
 <Box sx={{
                  width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                  gap: '16px', flexDirection: 'column', }}>
                  <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, width: '100%', justifyContent: 'center', alignItems: 'stretch', gap: '16px' }}>
                    <Box sx={{ gap: '10px', display: 'flex', flexDirection: 'column', width: '100%', flex:1 }}>
                    <TypographyComponent sx={{ fontSize: '16px', 
                                        color: Colors.black_80,fontWeight:400 }}>Nick name</TypographyComponent>
                                    <TextfieldComponent
                                        variant="outlined"
                                        value={nickName}
                                        name="nickName"
                                        placeholder="Enter your nick name"
                                        error={!!nickNameError}
                                        onChange={handleNickNameChange} 
                                        />
                                        {nickNameError && 
                                        <TypographyComponent sx={{color: Colors.Red}} >{nickNameError}</TypographyComponent>}
                    </Box>
                    <Box sx={{ gap: '10px', display: 'flex', flexDirection: 'column', width: '100%',flex:1 }}>
                    <TypographyComponent sx={{ fontSize: '16px', 
                                        color: Colors.black_80,fontWeight:400 }}>Phone number</TypographyComponent>
                                    <TextfieldComponent
                                        variant="outlined"
                                        value={phoneNo}
                                        name="phoneNo" 
                                        placeholder="Enter your phone number"
                                        onChange={handlePhoneNoChange}
                                        onKeyDown={handleKeyDownPhone}
                                        inputProps={{ maxLength: 12 }}
                                        error={!!phoneError} 
                                        />
                                      {phoneError && <TypographyComponent sx={{color: Colors.Red}} >{phoneError}</TypographyComponent>}                                        
                    </Box>
                    
                  </Box>                 

                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
                    <Box sx={{ gap: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <TypographyComponent sx={{ fontSize: '16px', 
                                        color: Colors.black_80,fontWeight:400 }}>Message</TypographyComponent>
                                     <TextField variant="outlined" multiline rows={3} placeholder="Type your queries"
                                                value={message}
                                                name="message"
                                                onChange={handleMessageChange}
                                                error={!!messageError}
                                                sx={{
                                                    height: '84px',
                                                    width: '100%',
                                                    padding: 0,
                                                    '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: '1px solid #c5c5c5',
                                                        borderColor: messageError ? Colors.Red : Colors.black_60,
                                                        transition: 'border-color 0.3s ease',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: messageError ? Colors.Red : Colors.black_80, 
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                    borderColor: messageError ? Colors.Red : Colors.black_80,
                                                    },
                                                    '& .MuiInputBase-input::placeholder': {
                                                    lineHeight:'18px',
                                                        fontFamily:'SF Pro Display',
                                                        fontSize: '14px',
                                                        fontWeight: 400,
                                                        color: Colors.black_70,
                                                    },
                                                    '& .MuiInputBase-input': {                              
                                                        fontSize: '14px', lineHeight:'18px',
                                                        fontFamily:'SF Pro Display',
                                                        fontWeight: 400,
                                                        color: Colors.black_80,
                                                    },
                                                    },                           
                                                }}
                                            />
                                      {messageError && <TypographyComponent sx={{color: Colors.Red}} >{messageError}</TypographyComponent>}                                        
                    </Box>
                  </Box>

                  <Box sx={{
                  width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                  gap: '16px', flexDirection: { xs:'column', md: 'row' } ,
                }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <ButtonRed type="submit" sx={{ width: { xs:'30%', md: '16%' }, gap: '4px' }}>Submit</ButtonRed>
                    </Box>
                  </Box>
                  
                </Box>
                </form>
               
              </Box>             
             
            </Box>
        } 
            
          {isSuccessMessage &&
            <Box sx={{
              width: '100%', height: '420px', display: 'flex', justifyContent: 'center',
              alignItems: 'center', flexDirection: 'row', gap: '8px',
            }}>
              <Box sx={{
                width: '100%',  height: {xs:'180px',md:'74px',sm:'180px'}, display: 'flex', justifyContent: 'center',
                alignItems: 'center', flexDirection: 'row', gap: '8px', background: Colors.white_80,
                borderRadius: '4px'
              }}>
                <CheckCircleIcon style={{ color: '#0BEA14', backgroundColor: '#FFFFFF', borderRadius: '50%', }} />
                <TypographyComponent sx={{
                  fontWeight: 400, fontSize: '18px', color: Colors.black_70
                }}>
                  Your message has been sent successfully. We appreciate you reaching out to us. Our team will get back to you as soon as possible.
                </TypographyComponent>
              </Box>
            </Box>
             }  
        

        {isTermPopUp && (
           <Box sx={{
            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '2',

        }}>
            <Box sx={{
                width: '100%', height: '100%', gap: '5px',
                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                display: 'flex', flexDirection: 'column',
            }}>
                <Box sx={{
                    width: { md: '35%', sm: '35%', xs: '85%' }, height: '36px',
                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                }}>
                     <IconButton 
                    //  onClick={handleCloseTerm} 
                     sx={{
                    position: 'fixed', width: '36px', height: '36px',}} >
                        <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                        background:'none'},}} ></CloseOutlinedIcon>
                    </IconButton>
                </Box>
                <Box
                    sx={{width: { md: '35%', sm: '35%', xs: '85%' },height: 'fit-content',
                        backgroundColor: Colors.white, border: '1px solid',
                        borderColor: Colors.white, borderRadius: '4px ',boxShadow: 'none',
                        boxSizing: 'border-box',zIndex: '3', alignItems: 'center', 
                    }}>

                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                        justifyContent: 'center', alignItems: 'center', height: '100%',
                    }}>
                          <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',paddingTop:'7%',
                                justifyContent: 'center', alignContent: 'center', height: '86%',maxWidth:'80%',
                                paddingBottom:'7%'
                             }}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',
                            justifyContent: 'center', alignContent: 'center', height: '100%', alignItems: 'stretch',
                        }}>
                            <TypographyComponent sx={{textAlign: 'left' }}>
                            Terms And Condition
                            </TypographyComponent>

                            <TypographyComponent sx={{textAlign: 'left', paddingTop:'8px' }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </TypographyComponent>

                           
                        </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
        )} 


                </Box>
            </Box>
            <Footer currentPageFooter={'Contact us'}></Footer>
        </Grid2>

    );
};

export default ContactUs;

