import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Service from './Pages/Services';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import { HelmetProvider } from 'react-helmet-async';
import Blog from './Pages/Blog';
import BlogContent from './Pages/BlogContent';
import BlogCategory from './Pages/BlogCategory';

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <div style={{ height: '100%' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/services" element={<Service />} />
            <Route path="/services/:serviceName" element={<Service />} />
            <Route path="/blog" element={<Blog/>} />
            <Route path="/blog/:blogTitle" element={<BlogContent />} />
            <Route path="/category/:blogCategory/" element={<BlogCategory />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
          

          </Routes>
        </div>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
