
import React, { useState } from 'react';
import { Box, colors, Grid2, IconButton, InputAdornment, Menu, MenuItem } from '@mui/material';
import Header from '../Components/Header';
import headerbg from '../Images/headerbg.png'
import Colors from '../Components/Colors';
import Footer from '../Components/Footer';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TypographyComponent from '../Components/TypographyComponent';
import { useMediaQuery } from '@mui/material';
import bgmob from '../Images/bgmob.jpg';
import { Helmet } from 'react-helmet-async';
import TextfieldComponent from '../Components/TextFeildComponent';
import searchicon from '../Images/searchicon.svg'
import closeicon from '../Images/closeicon.svg'
import blog1 from '../Images/blog1.png'
import { useRef } from 'react';
import categoryicon from '../Images/category.svg'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const BlogComponent = (props) => {

    function generateUrlFromTitle(title) {
        return title.toLowerCase().replace(/\s+/g, '-');
      }
    const {selectedCategory} = props

    const isDesktop = useMediaQuery('(min-width:768px)');
    const [searchValue, setSearchValue] = useState('')    
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const postsPerPage = 3;

    const navigate = useNavigate();

    const boxRef = useRef(null);


    const blogs = [
        {
            blogId: 1,
            categories: ['PPC', 'SEO'] ,
            title: 'How to Choose the Right Digital Marketing Agency for Your Business',
            content: `
            <p>Choosing an online digital marketing agency for an online presence is such a making your brand stand out. With so many companies to choose from, it's important to find a digital marketing agency that aligns with your vision for achieving business goals. You need a partner that specializes in SEO services, social media marketing, and web design. But how do you find that perfect fit? Let’s break down what it takes to find the right agency for your needs.</p>
      
            <h2>1. Define Your Business Goals</h2>
            <p>This is to be done by identifying what you want to get out of having an agency fulfill your marketing needs. Are you looking for search engine optimization for improved search ranking? Do you want a new website design that will elevate user experience, or do you wish to maximize your brand exposure with social media marketing?</p>
            <p>Now that you have clarified what it is that you actually need, you can go ahead and make a choice of Helping Flock as a potential agency to work with. They could offer full digital solutions, all particularly tailored for your needs. From improving website traffic to developing a solid online presence on social media.</p>
      
            <h2>2. Check the Agency's Range of Services</h2>
            <p>An authentic digital marketing agency should offer full-fledged services to execute each aspect of online marketing. When identifying possible partners, key offerings that you will be looking for include:</p>
            <ul>
              <li><strong>SEO Services</strong>: Expertise to thrive visibility of your website on the search engines by optimizing keywords, building links, and creating content.</li>
              <li><strong>Website Design and Development</strong>: The knowledge to develop user-friendly mobile websites that attract visitors and convert them.</li>
              <li><strong>Social Media Marketing</strong>: Strategic ideation and execution with the intention of turning out staggering engagements and a loyal audience on platforms such as Facebook, Instagram, and LinkedIn.</li>
            </ul>
            <p>Digital marketing companies extend a panoply of digital marketing services to ensure the convenience of having everything under a single roof for businesses.</p>
      
            <h2>3. Evaluate Their Industry Experience</h2>
            <p>Not all agencies have direct experience in your specific industry. Pinpointing a digital marketing agency with a proven track record in your niche is important as it allows them to adequately interpret market dynamics and customer behavior.</p>
            <p><strong>Portfolio and Case Studies</strong>: Request examples of previous work, and look at the portfolio.</p>
            <p><strong>Client Testimonials</strong>: Reviews of the agency will provide insight into how well it performs and how satisfied clients are.</p>
      
            <h2>4. Assess Their SEO and Marketing Strategies</h2>
            <p>A well-known digital marketing agency should have a satisfactory grasp of how search engine optimization works, since it is at the kernel of any online promotion strategy. Astutely, Flock comprehends and possesses commendable expertise in formulating ethical SEO strategies that are driven by research, aimed at improving your site's ranking in search engines.</p>
            <ul>
              <li><strong>Customized SEO Strategy</strong>: Companies do not believe in a one-size-fits-all approach. Rather, we take time to grasp your business requirements and work towards customizable SEO plans that deliver measurable results.</li>
              <li><strong>Proven Techniques</strong>: Ours is an expert team of search engine optimization specialists who use white-hat techniques integrating keyword research, on-page optimization, and quality link development to further enhance your online visibility.</li>
            </ul>
      
            <h2>5. Examine Communication and Transparency</h2>
            <p>The bounds of effective communication are essential for achieving successful cooperative endeavors. The digital marketing agency is, therefore, an extremely welcomed evening guest into your house, Keeping Flock—a pact on radical, transparent communication throughout your campaign.</p>
            <ul>
              <li><strong>Regular Updates</strong>: Consistent performance reports that update such parameters as website traffic, keyword rankings, and social media involvement.</li>
              <li><strong>Dedicated Account Manager</strong>: Each client is assigned an account manager, be that a person whom you'll have one point of contact with and/or who understands your business very well.</li>
            </ul>
      
            <h2>6. Consider Their Pricing and Value for Money</h2>
            <p>While pricing is crucial, it would be wise to consider value and return on investment more than cost. Some companies offer pricing that is mid-range with a high focus on results that justify your investment.</p>
            <ul>
              <li><strong>Transparent Pricing</strong>: Some companies are open about their pricing paradigm, allowing clients insight into what their investment entails and the anticipated outcome.</li>
              <li><strong>Focus on ROI</strong>: Our aim is to stretch your marketing dollar, implementing strategies that work to derive maximal conversions and thus augment your bottom line.</li>
            </ul>
      
            <h2>7. Seek a Long-Term Partnership</h2>
            <p>It should be emphasized that these kinds of agencies, including SEO and social media, should stay closer to their clients for evolving service development rather than using them as a short-term benefit. Some agencies are committed to developing long-lasting associations with clients, providing scalable alternatives to grow with their business.</p>
            <ul>
              <li><strong>Scalable Services</strong>: They can scale its offerings to adapt its services as needed, with custom offerings that cover advanced SEO services, expanded social media marketing, or a complete redesign of the website.</li>
            </ul>
      
            <h2>Why Choose Digital Marketing for Your Business?</h2>
            <p>What's more, over 90% of Canadians spend a significant amount of time online each day, making decisions on what to buy via internet searches and social media platforms. It isn't just cost-effective, it's a salesperson that never sleeps. It makes sure it delivers information to the customers searching for your services, even when you're not awake. You can analyze every click; you know what your actual results are and can adjust your strategy in an instant. Whether it's a one-person operation or a massively growing business, digital marketing levels the playing field so that even with small budgets you would be able to compete with bigger players and build genuine relationships with your targeted audience. Digital marketing is flexible enough to grow and morph with your business, wherever it may take you. Nowadays, it would still be wise to invest in an online presence; you shall, henceforth, simply die a slow death.</p>
      
            <h2>Conclusion</h2>
            <p>Choosing the right digital marketing agency could significantly enhance the competitive commercial environment. First, you should search for services offered, previous industry experience, communication, and ROI focus in choosing an agency that fits your business.</p>
          `,
            publishDate: 'Published On November/13/2024',
            image: blog1,
            url: generateUrlFromTitle('How to Choose the Right Digital Marketing Agency for Your Business')
          },
        
      ];

  
      const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        const filtered = blogs.filter(blog => 
          blog.title.toLowerCase().includes(value.toLowerCase()) || 
          blog.categories.some(category => category.toLowerCase().includes(value.toLowerCase()))
        );
        setFilteredBlogs(filtered);
        if (boxRef.current) {
          if (value) {
            boxRef.current.style.display = 'block'; 
          } else {
            boxRef.current.style.display = 'none';  
          }
        }
      };

      const handleClearSearch = () => {
        setSearchValue('');
        if (boxRef.current) {
            boxRef.current.style.display = 'none';  
          }
      };

      const handleSelectBlog = (blog) => {
        setSearchValue(blog.title); 
        if (boxRef.current) {
            boxRef.current.style.display = 'none'; 
          }
      };

      const handleClickOutside = (event) => {
        if (boxRef.current && !boxRef.current.contains(event.target)) {
          setSearchValue(''); // Clear search value or close the dropdown
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

      const handleBoxClick = (link) => {
            navigate(link);
        };

        const sortedBlogs = blogs.sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate));
        const currentPosts = sortedBlogs.slice(startIndex, startIndex + postsPerPage);

        const handleNext = () => {
            // Move to the next post (increment by 1)
            setStartIndex((prevIndex) => Math.min(prevIndex + 1, sortedBlogs.length - 1));
        };
        
        const handlePrev = () => {
            // Move to the previous post (decrement by 1)
            setStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        };
    
        

    return (
        <Grid2 container sx={{
            width: '100%', height: '100%', overflow: 'hidden',
            justifyContent: 'center', alignItems: 'center'
        }}>
            
                    

            <Box sx={{
                height: '100%', display: 'flex', width: '100%', maxWidth: '90%', gap: '34px',
                paddingTop: {sm:'2%',md:'2%',xs:'5%'}, paddingBottom: '3%', flexDirection: 'column'
            }}>
                <Box sx={{
                height: '100%', display: 'flex', width: '100%', gap: '34px',flexDirection: 'column'
                }}>

                 <Box sx={{
                height: '100%', display: 'flex', width: '100%',flexDirection: 'column'
                }}>   
                <TextfieldComponent
                variant="outlined"
                sx={{ width: '50%' }} // Ensure matching width
                value={searchValue}
                name="search"
                placeholder="Search blogs"
                onChange={handleSearchChange}
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                    {searchValue ? (
                        <IconButton onClick={handleClearSearch}>
                        <img src={closeicon} alt="Close" style={{ width: '16px', height: '16px' }} />
                        </IconButton>
                    ) : (
                        <img src={searchicon} alt="Search" style={{ width: '16px', height: '16px' }} />
                    )}
                    </InputAdornment>
                ),
                }}/>

                {searchValue && (
                <Box
                    ref={boxRef}
                    sx={{
                    width: '45%',
                    position: 'absolute',
                    marginTop: '48px', // Adjust based on header height
                    borderRadius: '0px 0px 4px 4px',
                    paddingBottom: '8px',
                    maxHeight: '170px',
                    overflow: 'auto',
                    boxShadow: ' 0px 4px 4px 0px #00000040',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '0px solid',
                    borderColor: 'grey',
                    background: 'white',
                    paddingTop: '8px',
                    zIndex: 9,
                    gap: '4px',
                    '&::-webkit-scrollbar': {
                        width: '11px',
                        height: '8px',
                        borderRadius: '16px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'grey',
                        borderRadius: '16px',
                        border: '2px solid white',
                    },
                    '&::-webkit-scrollbar-track': {
                        borderRadius: '16px',
                        backgroundColor: 'white',
                    },
                    }}
                >
                    {filteredBlogs.length === 0 ? (
                    <Box
                        sx={{
                            width: 'calc(100% - 32px)',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '8px',
                            padding: '4px 16px',                          
                            justifyContent: 'flex-start',
                            color: Colors.black_80,
                        }}
                    >
                         <TypographyComponent sx={{ minWidth: 'fit-content', color: 'inherit' }}>
                         No results found for your search.
                        </TypographyComponent>
                       
                    </Box>
                    ) : (
                    filteredBlogs.map((blog, index) => (
                        <Box
                        key={index}
                        sx={{
                            width: 'calc(100% - 32px)',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '8px',
                            padding: '4px 16px',
                            cursor: 'pointer',
                            justifyContent: 'flex-start',
                            color: Colors.black_80,
                            '&:hover': { backgroundColor: Colors.Red, color: Colors.white },
                        }}
                        onClick={() => { /* handle click on a blog item */ }}
                        >
                        <TypographyComponent sx={{ minWidth: 'fit-content', color: 'inherit' }}>
                            {blog.title}
                        </TypographyComponent>
                        </Box>
                    ))
                    )}
                </Box>
                )}
                </Box> 

                <Box sx={{ width: '100%',  height: '100%', justifyContent: 'flex-start', alignItems:'flex-start',
                    display: 'flex', flexDirection: 'column', gap: {xs:'16px', md:'32px'}, }}>
                    <TypographyComponent sx={{fontSize:'32px', fontWeight:500}}>Categories</TypographyComponent>
                    <Box sx={{ width: '100%',  height: 'fit-content', justifyContent: 'flex-start', alignItems: 'center',
                    display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: {xs:'16px', md:'32px'}, }}>
                    {[
                    { label: "All", link: `/services` },
                    { label: "PPC", link: `/services/ppc` }, 
                    { label: 'SEO', link: `/services/seo`},
                    { label: 'Content Creation', link: `/services/content-creation` } , 
                    { label: 'Email Marketing', link: `/services/email-marketing` } , 
                    { label: 'UI-UX Designing', link:`/services/ui-ux-designing` } , 
                    { label: 'UI-UX Audit', link: `/services/ui-ux-audit`} , 
                    { label: 'Social Media Marketing', link: `/services/social-media-marketing` },
                    { label: 'Brand Management', link: `/services/brand-management`} , 
                    
                    ]
                    .filter(item => item.label)
                    .map((item, index) => (
                        <Box key={index}
                        onClick={() => {
                            if (item.label === "All") {
                                window.location.href = '/blog';  // Redirect to '/blog' for 'All'
                            } else {
                                window.location.href = `/category/${item.label}`;  // Redirect to category for other labels
                            }
                        }}
                        sx={{display: 'flex', alignItems: 'center',justifyContent: 'center',
                        width:  'fit-content', height:'48px', background: selectedCategory === item.label ? Colors.Red :  '#F1F1F1',
                        minWidth:'160px',  paddingLeft: {xs:'8px', md:'16px'}, paddingRight: {xs:'8px', md:'16px'} ,
                        boxSizing: 'border-box', cursor: 'pointer', borderRadius: '26px',color: selectedCategory === item.label ? Colors.white: Colors.black_80 ,
                        '& .typography': {
                            color: selectedCategory === item.label ? Colors.white: Colors.black_80,},
                        '&:hover': { background: Colors.Red, color: Colors.white, '& .typography': {
                            color: Colors.white, } }}}>
                        <TypographyComponent
                            className="typography" sx={{ fontSize:{xs:'12px', md: '20px'} , fontWeight:400, color: 'inherit'}} >
                            {item.label}
                        </TypographyComponent>
                        </Box>
                    ))}
                </Box>
                </Box>

                <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: { xs: '16px', md: '32px' } }}>
            <TypographyComponent sx={{ fontSize: '32px', fontWeight: 500 }}>Recent Posts</TypographyComponent>
            
            <Box
  sx={{
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative', // Allow absolute positioning for the icons
  }}
>
  {/* Left Arrow Button */}
  {startIndex > 0 && (
    <IconButton
      onClick={handlePrev}
      sx={{
        position: 'absolute',
        left: '-20px', // Position outside the left border
        top: '50%', // Vertically center the icon
        transform: 'translateY(-50%)',
        backgroundColor: Colors.Red,
        borderRadius: '50%',
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          backgroundColor: Colors.hovering_red,
        },
      }}
    >
      <ArrowBackIosNewIcon sx={{ color: 'white', fontSize: 20 }} />
    </IconButton>
  )}

  {/* Post List */}
  <Box
    sx={{
      display: 'flex',
      gap: '32px',
      width: '100%',
      justifyContent: 'space-between',
    }}
  >
    {Array.from({ length: postsPerPage }).map((_, index) => {
      const post = currentPosts[index];
      return post ? (
        <Box
          key={post.id}
          sx={{
            flex: '1 1 calc(33.333% - 32px)',
            flexDirection: 'column',
            border: '1px solid #D2D2D2',
            padding: '16px',
            height: '624px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: '20px',
            '&:hover': { border: '1px solid #313131' },
          }}
        >
          <img
            src={post.image}
            alt="blog"
            style={{ width: '100%', height: '320px', cursor: 'pointer' }}
            onClick={() => {
              window.location.href = `/blog/${post.url}`;
            }}
          />
          <TypographyComponent
            onClick={() => {
              window.location.href = `/blog/${post.url}`;
            }}
            sx={{
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '26px',
              cursor: 'pointer',
              '&:hover': { color: Colors.Red },
            }}
          >
            {post.title}
          </TypographyComponent>

          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', flexDirection: 'column' }}>
            <Box
              sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 4,
              }}
            >
              <TypographyComponent sx={{ fontSize: '18px', fontWeight: 400, lineHeight: '32px' }}>
                {post.content}
              </TypographyComponent>
            </Box>
            <TypographyComponent
              onClick={() => {
                window.location.href = `/blog/${post.url}`;
              }}
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '32px',
                color: '#EB5648',
                cursor: 'pointer',
                '&:hover': { color: Colors.hovering_red },
              }}
            >
              Read more
            </TypographyComponent>
          </Box>

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <TypographyComponent
              sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '22px', color: '#626262' }}
            >
              {post.publishDate}
            </TypographyComponent>
            <TypographyComponent
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '22px',
                color: '#626262',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: '8px',
              }}
            >
              {post.categories.map((category) => (
                <Box
                  key={category}
                  onClick={() => {
                    window.location.href = `/category/${category}`;
                  }}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '2px',
                    cursor: 'pointer',
                  }}
                >
                  <img
                    src={categoryicon}
                    alt={`${category} icon`}
                    style={{ width: '20px', height: '20px' }}
                  />
                  {category}
                </Box>
              ))}
            </TypographyComponent>
          </Box>
        </Box>
      ) : (
        <Box
          key={index}
          sx={{
            flex: '1 1 calc(33.333% - 32px)',
            height: '624px',
            border: '1px solid transparent',
            backgroundColor: 'transparent',
          }}
        />
      );
    })}
  </Box>

  {/* Right Arrow Button */}
  {startIndex + postsPerPage < sortedBlogs.length && (
    <IconButton
      onClick={handleNext}
      sx={{
        position: 'absolute',
        right: '-20px',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: Colors.Red,
        borderRadius: '50%',
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          backgroundColor: Colors.hovering_red,
        },
      }}
    >
      <ArrowForwardIosIcon sx={{ color: 'white', fontSize: 20 }} />
    </IconButton>
  )}
</Box>


        </Box>
   





                
                </Box>
                
                </Box>
           
        </Grid2>

    );
};

export default BlogComponent;
