

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slider1 from '../Images/slider1.png';
import slider2 from '../Images/slider2.png';
import slider3 from '../Images/slider3.png';
import slider4 from '../Images/slider4.png';
import slider5 from '../Images/slider5.png';
import mobslider1 from '../Images/mobslider1.png';
import mobslider2 from '../Images/mobslider2.png';
import mobslider3 from '../Images/mobslider3.png';
import mobslider4 from '../Images/mobslider4.png';
import mobslider5 from '../Images/mobslider5.png';
import { Box} from '@mui/material';
import Colors from './Colors';
import Header from './Header';
import TypographyComponent from './TypographyComponent';
import { useMediaQuery } from '@mui/material';


const carouselOverlayStyles = `
    .carousel-item {
        position: relative;
        width: 100%;
        height: 92vh;
        overflow: hidden;
    }

    .carousel-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(45, 45, 45, 0.48);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
        padding: 20px;
        box-sizing: border-box;
        z-index: 1;
    }

    @media (max-width: 960px) {
        .carousel-item {
            height: 480px;
        }
    }

    @media (max-width: 600px) {
        .carousel-item {
            height: 520px;
        }
    }

    .carousel-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .slick-dots {
        position: absolute;
        bottom: 20px;
        display: flex;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;
        z-index: 2;
    }

    .slick-dots li {
        margin: 0 5px; /* Consistent spacing between dots */
        display: inline-block;
        position: relative;
    }

    .slick-dots li button {
        position: relative;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        background-color: #132436A1; /* Default inactive dot color */
        cursor: pointer;
        outline: none;
        transition: background-color 0.3s ease;
    }

    .slick-dots li.slick-active button {
        background-color: #DD223F; /* Active dot color */
    }

    /* Apply transformation only during slide transitions */
    .slick-dots li button:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: transparent;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .slick-dots li.slick-active button:before {
        opacity: 1;
    }

    .slick-dots li {
        transition: transform 0.8s ease;
    }

    .slick-dots li.slick-active :not(li:last-child) {
        transform: translateX(-30px); 
    }

    .slick-dots li.slick-active  {
        transform: translateX(30px); 
    }

    .slick-dots li:first-child.slick-active:not(li:first-child) {
        transform: none; 
    }

    .slick-dots li:last-child.slick-active:not(li:last-child) {
        transform: none; 
    }  

    .slick-dots li button:hover {
        background-color: #DD223F;
    }


`;

const carouselData = [
    { deskimage: slider1, mobimage: mobslider1 },
    { deskimage: slider2, mobimage: mobslider2 },
    { deskimage: slider3, mobimage: mobslider3 },
    { deskimage: slider4, mobimage: mobslider4},
    { deskimage: slider5, mobimage: mobslider5},
];

const HomeSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const isDesktop = useMediaQuery('(min-width:768px)');

    return (
        <>
            <style>{carouselOverlayStyles}</style>
            <Box sx={{ position: 'relative' }}>
                <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', zIndex: 1000 }}>
                    <Header currentPage={'Home'} />
                </Box>
                <Slider {...settings}>
                    {carouselData.map((slide, index) => (
                        <div key={index} className="carousel-item">
                            <img  src={isDesktop ? slide.deskimage : slide.mobimage}
                             alt={`Slide ${index + 1}`} className="carousel-image" />
                            
                              <Box className="carousel-overlay" sx={{display:'flex', flexDirection:'row',width:'100%',height:'100%',
                               justifyContent:'center', alignItems:'center'}}>
                                    <Box sx={{display:'flex', flexDirection:'row',width:'100%',height:'92%', maxWidth:'94%',
                                     paddingTop:'3%', paddingBottom:{xs:'25%', md:'4%'},}}>
                                        <Box sx={{display:'flex', flexDirection:'column',width:{xs:'80%', md:'50%'},height:'100%',
                                            justifyContent:'flex-end', alignItems:'flex-start',gap:'16px'}}>
                                                <TypographyComponent  sx={{fontSize:{xs:'20px', md:'48px'}, fontWeight:500, lineHeight:{xs:'24px', md:'56px'},
                                                     textAlign:'left', color: Colors.white}}>Design an Image for Your Brand via Digital Marketing</TypographyComponent>
                                                    
                                                <TypographyComponent  sx={{fontSize:{xs:'12px', md:'16px'}, lineHeight:{xs:'16px', md:'24px'},
                                                     textAlign:'left', color: Colors.white}}>Helping Flock is a digital marketing agency that’s all about connecting, rather than just offering a marketing service. Through tailor-made strategies, we help brands take flight and avoid getting grounded. Whether you are a startup in need of help to spread its wings or a company seeking higher heights of growth and expansion, our digital marketing agency has the tools and personnel suitable for what the occasion demands. Let us bridge the gap between your brand and the audience you aim to reach.
                                                
                                                <br />Let's get your brand flying towards success ! 
                                                </TypographyComponent>
                                        </Box>
                                        <Box sx={{display:'flex', flexDirection:'row',width:{xs:'20%', md:'50%'},height:'100%',}}>
                                    
                                        </Box>
                                    
                                    </Box>
                                
                             </Box>  
                               
                               
                           
                        </div>
                    ))}
                </Slider>
            </Box>
        </>
    );
};

export default HomeSlider;
