import React from 'react'
import {Grid2, Box } from '@mui/material'
import HomeSlider from '../Components/HomeSlider'
import Footer from '../Components/Footer'
import TypographyComponent from '../Components/TypographyComponent'
import Colors from '../Components/Colors'
import { useNavigate } from 'react-router-dom';
import ReviewSlider from '../Components/ReviewSlider'
import { Helmet } from 'react-helmet-async';




function Home() {

    const navigate = useNavigate();

    const handleBoxClick = (link) => {
          navigate(link);
      };
    
  return (
    <Grid2 container sx={{width: '100%', height: '100%',overflow:'hidden', justifyContent:'center', alignItems:'center' }}>

            <Helmet>
                <title>Digital Marketing Agency | SEO, PPC, SMM & more</title>
                <meta name="description" content="Helping Flock is a leading digital Marketing Agency.  We grow your business through SEO, PPC, web design, and development services. Call us Today!" />
                <link rel="canonical" href="https://helpingflock.com/home" />
            </Helmet>
            <h1 style={{ position: 'absolute', left: '-9999px' }}>Digital Marketing Agency</h1>

   
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>    
       <HomeSlider />   
    </Box> 
    
    
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%',height: '96%', maxWidth: '95%',
    paddingTop: '2%', paddingBottom: '2%',gap:'16px' }}>
        <Box sx={{ width: '100%',  height: 'fit-content', justifyContent: 'center', alignItems: 'center',
            display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: {xs:'16px', md:'24px'}, }}>
            {[
            { label: "PPC", link: `/services/ppc` }, 
            { label: 'SEO', link: `/services/seo`},          
            { label: 'Social Media Marketing', link: `/services/social-media-marketing` },
            { label: 'Content Creation', link: `/services/content-creation` } , 
            { label: 'Email Marketing', link: `/services/email-marketing` } , 
            { label: 'UI-UX Designing', link:`/services/ui-ux-designing` } , 
            { label: 'UI-UX Audit', link: `/services/ui-ux-audit`} , 
            { label: 'Brand Management', link: `/services/brand-management`} , 
            
            ]
            .filter(item => item.label)
            .map((item, index) => (
                <Box key={index} onClick={() => handleBoxClick(item.link)}
                sx={{display: 'flex', alignItems: 'center',justifyContent: 'center',
                width: { xs: 'calc(50% - 16px)', md: 'calc(25% - 24px)' }, height:{xs:'62px', md: '110px'}, background: '#F1F1F1',
                padding: '8px', boxSizing: 'border-box', cursor: 'pointer', borderRadius: '8px',
                '&:hover': { background: Colors.Red, color: Colors.white, '& .typography': {
                    color: Colors.white, } }}}>
                <TypographyComponent
                    className="typography" sx={{ fontSize:{xs:'12px', md: '20px'} , color: 'inherit'}} >
                    {item.label}
                </TypographyComponent>
                </Box>
            ))}
        </Box>

        <Box sx={{ width: 'calc(100% - 24px)',  height: '100%', justifyContent: 'left', alignItems: 'left',
            display: 'flex', flexDirection: 'column', gap: '8px',padding:'12px', }}>
                <TypographyComponent sx={{fontSize:{xs:'18px', md:'32px'}, fontWeight:500, lineHeight:{xs:'22px', md:'38px'},
                textAlign:'left'}}>Who we are</TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px'}} >Helping Flocks is not just a  Digital marketing agency, but a collective of inspired innovators, strategists, and creators. We come together to build brands in the digital window of existence by means of Brand Management, SEO services, Google Ads, social media marketing, and website design. Together, they are fueled with unified energy to see that every business, however big or small, finds its voice, reaches its audience, and maximizes its potential in the online space through search engine optimization and other proven methods. We believe in connections, creativity, and thoughtful analysis. Team members provide fresh new perspectives and expertise, balancing creative ideas with insights-driven strategies. We're very passionate about helping brands to shine in their online presence by providing highly individualistic approaches, dependent on the client's specific needs and objectives. Through SEO service, Google Ads, social media marketing, and website design, we combine creativity with data-driven insights to ensure that every decision we make is meaningful and aligned with the brand's vision.
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px'}} >With Helping Flocks, you're not merely following a trend. We're helping set it. Long-term relationships are, and should continue to be, important to us. Because we believe great client relations form the pillars of long-lasting success. As one of the Top 10 digital marketing companies in Canada, we consider ourselves an extension of your team that helps you ride the fast-moving digital waves. You would welcome a partner, a new soaring height for your brand with Helping Flocks. </TypographyComponent>
        </Box>

        <Box sx={{ width: 'calc(100% - 24px)',  height: '100%', justifyContent: 'left', alignItems: 'left',
            display: 'flex', flexDirection: 'column', gap: '8px',padding:'12px', }}>
                <TypographyComponent sx={{fontSize:{xs:'18px', md:'32px'}, fontWeight:500, lineHeight:{xs:'22px', md:'38px'},
                textAlign:'left'}}>Why choose helping flocks?</TypographyComponent>
                <TypographyComponent  sx={{lineHeight:'24px'}} >Helping Flocks stands out not in the humdrum crowd of proverbial digital noise but as a beacon of lucidity and purpose. Helping Flocks is a digital marketing agency that believes in the power of collaboration and the distinct power of each brand. Here is the reason that Helping Flocks should be your choice for digital marketing needs: We strongly believe that there can be no one-for-all approach in digital marketing. Every brand has its unique story, hence we create custom marketing strategies, including social media strategy and Canadian search engine optimization, based on your business goals and aligning with your target audience.
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px',fontSize:{xs:'18px', md:'20px'}, fontWeight:500, }} >Years of experience-rich team:
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px'}} >Our team of people for marketing, designing, and strategizing have years of industry experience under their belts. Be it SEO in Toronto, web development, or PPC campaigns in Canada, we stay always ahead in using the latest tools and trends to enhance your brand's visibility and position within the industry.
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px',fontSize:{xs:'18px', md:'20px'}, fontWeight:500, }} >Result-oriented approach:
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px'}} >Helping Flocks believes in results. We are committed to building, and delivering your business vision through measurable success-whether it's increasing your online presence via Google Ads in Canada, driving conversions, or fostering customer loyalty through personalized search engine optimization.
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px',fontSize:{xs:'18px', md:'20px'}, fontWeight:500, }} >Collaborative approach:
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px'}} >Your success is ours. In our journey together, we will get to know each other on a first-name basis. We will listen to all your needs and incorporate them into every step we take in the process. With you, we create a marketing plan reflective of your brand, its principles, and objectives. 
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px',fontSize:{xs:'18px', md:'20px'}, fontWeight:500, }} >Imported marketing:
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px'}} >Integrity starts with what we do. We value ethical marketing, whereby we grow your brand sustainably and meaningful. 
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px'}} >Choose Helping Flocks, one of the Top 10 digital marketing companies in Canada. Let's turn the journey of your brand from inspiring into impactful. 
                </TypographyComponent>
        </Box>

        <Box sx={{ width: 'calc(100% - 24px)',  height: '100%', justifyContent: 'left', alignItems: 'left',
            display: 'flex', flexDirection: 'column', gap: '8px',padding:'12px', }}>
                <TypographyComponent sx={{fontSize:{xs:'18px', md:'32px'}, fontWeight:500, lineHeight:{xs:'22px', md:'38px'},
                textAlign:'left'}}>What our clients say</TypographyComponent>
                <ReviewSlider></ReviewSlider>
               
        </Box>

    </Box>
 
   
   <Footer currentPageFooter={'Home'}></Footer>

   


  

     
    
    </Grid2>

)
}

export default Home