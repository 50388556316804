import React from "react"
import Typography from '@mui/material/Typography';
import Colors from "./Colors";


 function TypographyComponent({ onClick, sx, ...props }){
    
    return(
        <Typography 
            sx={{fontFamily:'SF Pro Display', fontWeight: 400, fontSize:{ xs: '12px', sm:'14px' ,md: '14px' },
             color: Colors.black_80, lineHeight:'20px',fontStyle:'normal', ...sx, }}
                 {...props} onClick={onClick}>            
         </Typography>
    )
}

export default TypographyComponent;

